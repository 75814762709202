import React from 'react'

import PropTypes from 'prop-types'

import './primary-pink-button.css'
import { Link } from 'react-router-dom'

const PrimaryPinkButton = (props) => {
    const onSubmit = (data) => {
        emailjs.sendForm('YOUR_SERVICE_ID', 'procureglobe_templace', '#myForm').then(
            (response) => {
              console.log('SUCCESS!', response.status, response.text);
            },
            (error) => {
              console.log('FAILED...', error);
            },
          );
    }
    

  return (
    <div className="primary-pink-button-container">
        <Link to="/contact-us">
      <button className="primary-pink-button-button ButtonSmall button"  onClick={onSubmit}>
        {props.button}
      </button>
      </Link>
    </div>
  )
}

PrimaryPinkButton.defaultProps = {
  button: 'Button',
}

PrimaryPinkButton.propTypes = {
  button: PropTypes.string,
}

export default PrimaryPinkButton
